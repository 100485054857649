.base-main {
  margin-bottom: 128px;
}
.base-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}
.base-headline {
  margin-top: 32px;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.base-headline p {
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  padding: 0 32px;
}
.base-headline p span {
  font-size: 12px;
  margin-top: 4px;
  display: block;
}
.base-headline svg {
  width: 68px;
  height: 60px;
  opacity: 0.2;
}
.base-headline svg:first-child {
  transform: scale(-1, 1);
}
.base-singleTop {
}
.base-column {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base-column__main {
  width: calc(100% - 320px - 32px);
}
.price .base-column__main {
  width: calc(100% - 400px - 32px);
}
.base-column__main__section {
  padding: 32px;
  position: relative;
}
.base-column__main__section__bg {
  width: 100%;
  height: 100%;
  border-radius: 0 16px 16px 16px;
  position: absolute;
  top: 0;
  left: 0;
}
.base-column__main__section > *:not(.base-column__main__section__bg) {
  position: relative;
}
.base-column__main__section + .base-column__main__section {
  margin-top: 96px;
}
.base-column__ttl {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute !important;
  top: -64px;
  left: 0;
}
.base-column__ttl p {
  width: 400px;
  height: 64px;
  padding: 0 32px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}
.base-column__ttl__bg {
  width: 100%;
  height: 100%;
  border-radius: 16px 16px 0 0;
  position: absolute;
  top: 0;
  left: 0;
}
.base-column__ttl h2 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
}
.base-column__ttl svg {
  width: 54px;
  height: 48px;
}
.base-column__ttl.custom svg {
  opacity: 1;
}
.base-column__calendar {
  width: 320px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  position: sticky;
  top: 16px;
}
.price .base-column__calendar {
  width: 400px;
}
.base-column__calendar > span {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}
.base-column__calendar > *:not(span) {
  position: relative;
}
.base-column__spFixed {
  display: none;
}

@media screen and (max-width: 900px) {
  .price .base-headline {
    padding: 0 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: space-between;
  }
  .price .base-headline p {
    font-size: 24px;
    padding: 0;
  }
  .price .base-singleTop {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .price .base-column {
    margin-top: 16px;
    padding: 0;
    display: block;
  }
  .price .base-column__main {
    width: 100%;
  }
  .price .base-column__main__section {
    padding: 16px;
  }
  .price .base-column__ttl {
    top: -48px;
  }
  .price .base-column__ttl h2 {
    font-size: 18px;
  }
  .price .base-column__ttl p {
    width: 260px;
    height: 48px;
    padding: 0 16px;
  }
  .price .base-column__ttl svg {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .price .base-column__calendar {
    width: 100%;
    position: relative;
    top: 0;
    padding: 16px;
    margin: 32px auto 0 auto;
    background: inherit;
  }
  .price .base-column__spFixed {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .price .base-column__spFixed__bg1 {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .price .base-column__spFixed__bg2 {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .price .base-column__spFixed__btn {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}
@media screen and (max-width: 768px) {
  .base-headline {
    padding: 0 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    justify-content: space-between;
  }
  .base-headline p {
    font-size: 24px;
    padding: 0;
  }
  .base-singleTop {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base-column {
    margin-top: 16px;
    padding: 0;
    display: block;
  }
  .base-column__main {
    width: 100%;
  }
  .base-column__main__section {
    padding: 16px;
  }
  .base-column__ttl {
    top: -48px;
  }
  .base-column__ttl h2 {
    font-size: 18px;
  }
  .base-column__ttl p {
    width: 260px;
    height: 48px;
    padding: 0 16px;
  }
  .base-column__ttl svg {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
  .base-column__ttl__bg {
  }
  .base-column__calendar {
    position: relative;
    top: 0;
    margin: 32px auto 0 auto;
  }
  .base-column__spFixed {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base-column__spFixed__bg1 {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .base-column__spFixed__bg2 {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base-column__spFixed__btn {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}
/*   
  .base-padding__8-8 { padding: 8px; }
  .base-padding__8-16 { padding: 8px 16px; }
  .base-padding__16-8 { padding: 16px 8px; }
  .base-padding-16 { padding: 16px; }
  .base-padding-16-32 { padding: 16px 32px; }
  .base-padding-32-16 { padding: 32 16px; }
  
  .base-marginTop-8 { margin-top: 8px; }
  .base-marginTop-16 { margin-top: 16px; }
  .base-marginTop-32 { margin-top: 32px; }
  
  .base-marginBottom-8 { margin-bottom: 8px; }
  .base-marginBottom-16 { margin-bottom: 16px; }
  .base-marginBottom-32 { margin-bottom: 32px; }
   */
.color-txt {
  color: #651800;
  color: var(--theme-text-color);
}
.color-txt--white {
  color: #fff !important;
}
.color-bg {
  background: #fffaeb;
  background: var(--theme-background-color);
}
.color-bg--white {
  background: #fff !important;
}
.color-bg--black {
  background: #651800;
  background: var(--theme-text-color);
}
.color-bor--white {
  border-color: #fff !important;
}
.color-bor--black {
  border-color: #651800;
  border-color: var(--theme-text-color);
}
.color-fill {
  fill: #fffaeb;
  fill: var(--theme-background-color);
}
.color-gradient {
  background: linear-gradient(to bottom, rgba(255, 250, 235, 0) 0%, rgba(255, 250, 235, 1) 100%);
}
/* color change */
.color-main--txt {
  color: #ff5a26;
  color: var(--theme-primary-color);
}
.color-sub--txt {
  color: #febf52;
  color: var(--theme-secondary-color)
}
.color-main--bor {
  border-color: #ff5a26;
  border-color: var(--theme-primary-color);
}
.color-sub--bor {
  border-color: #febf52;
  border-color: var(--theme-secondary-color)
}
.color-main--bg {
  background: #ff5a26;
  background: var(--theme-primary-color);
}
.color-sub--bg {
  background: #febf52;
  background: var(--theme-secondary-color);
}
.color-footer-background {
  background: #febf52;
  background: var(--theme-footer-background-color);
}
.color-content-background {
  background: #febf52;
  background: var(--theme-content-section-background-color);
}
.color-main--fill {
  fill: #febf52;
  fill: var(--theme-secondary-color);
}
.color-sub--fill {
  fill: #febf52;
  fill: var(--theme-content-section-background-color);
}
