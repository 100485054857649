.item__li__pic {
  width: 100%;
  height: 320px;
  position: relative;
}
.item__li__pic img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.item__li__sub {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.item__li__sub__tag {
  width: calc(100% - 200px);
  margin: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item__li:nth-child(5n-2) .item__li__sub__tag,
.item__li:nth-child(5n-1) .item__li__sub__tag,
.item__li:nth-child(5n) .item__li__sub__tag {
  width: 100%;
  margin-bottom: 40px;
}
.item__li__sub__tag p {
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-all;
}
.item__li__sub__erea {
  width: 200px;
  height: 32px;
  border-radius: 16px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item__li__sub__erea p {
  font-size: 16px;
  font-weight: bold;
}
.item__li__sub__erea svg {
  width: 16px;
  height: 16px;
  position: absolute;
  transform: rotate(180deg);
}
.item__li__sub__erea svg:first-child {
  top: -16px;
  right: 0;
}
.item__li__sub__erea svg:last-child {
  bottom: 0;
  left: -16px;
}
.item__li__info {
}
.item__li__info__ttl {
  height: 54px;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  margin-top: 8px;
}
.item__li__info__desc {
  height: 42px;
  margin-top: 8px;
  font-size: 14px;
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.item__li__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.item__li__info__bottom__week {
  font-size: 12px;
}
.item__li__info__bottom__price {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .item__li:nth-child(5n-4),
  .item__li:nth-child(5n-3),
  .item__li:nth-child(5n-2),
  .item__li:nth-child(5n-1),
  .item__li:nth-child(5n) {
    width: 100%;
  }
  .item__li:nth-child(5n-4),
  .item__li:nth-child(5n-2),
  .item__li:nth-child(5n-1) {
    margin-right: 0;
  }
  .item__li__sub__tag {
    width: 100%;
    margin-bottom: 40px;
  }
  .item__li__pic {
    width: 100%;
    height: 240px;
    flex-shrink: 0;
  }
  .item__li__info__ttl {
    font-size: 18px;
    -webkit-line-clamp: 2;
    margin-top: 8px;
    order: 0;
  }
}
@media screen and (max-width: 768px) {
}

.c-card__pic__labelframe {
  position: absolute;
  top: 8px;
  left: 0;
}
.c-card__pic__labelframe > div + div {
  margin-top: 4px;
}
.c-card__pic__labelframe__recommend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-card__pic__labelframe__recommend div {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.c-card__pic__labelframe__recommend div {
  background: #2cb582;
}
.c-card__pic__labelframe__recommend div img {
  width: 16px;
  height: 16px;
}
.c-card__pic__labelframe__recommend p {
  width: 132px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  margin-left: -36px;
  position: relative;
}
.c-card__pic__labelframe__recommend p::before,
.c-card__pic__labelframe__recommend p::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__recommend p::after {
  transform: scale(1, -1);
}
.c-card__bottom__original__price {
  text-align: right;
  color: #888;
  text-decoration: line-through;
}
.c-card__pic__labelframe__promotion {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #ff0045;
  padding: 0 8px;
  position: relative;
  display: inline-block;
}
.c-card__pic__labelframe__promotion::before,
.c-card__pic__labelframe__promotion::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__promotion::after {
  transform: scale(1, -1);
}
